import { ActionButton } from '@components/ui/button/ActionButton'
import { Spinner } from '@components/ui/spinner/Spinner'
import { db } from '@lib/firebase'
import { callCloud } from '@lib/helpers/callCloud'
import { PushCenter } from '@models/nots/pushCenter'
import { doc, updateDoc } from 'firebase/firestore'
import React, { useState } from 'react'
import toast from 'react-hot-toast'
import * as Sentry from '@sentry/react';
import { selectCenter } from '@redux/features/gquerySlice/gquerySlice'
import { useAppSelector } from '@redux/store/hooks'

interface Props {
    push: PushCenter,
    userUid: string,
    usersPush: number,
}
export const ReuseNot = ({push, userUid, usersPush}: Props) => {
    const center = useAppSelector(selectCenter)
    const [loading, setloading] = useState(false)
    const updateNot = async (id: string) => {
        const date = new Date().toLocaleDateString('es-Es');
        await updateDoc(doc(db, `pushCenter/${id}`), {
            numUsers: usersPush,
            dateShow: date,
            lastSender: userUid
        })
    }

    const reuse = async () => {
        if (usersPush === 0) {
            return toast.error('Actualmente ningún usuario tiene la app')
        }
        setloading(true)
        try {
            const centerTopic = callCloud('centerTopic');
            await centerTopic({ title: push.title, mess: push.mess, notificationId: center?.notificationId })
            toast.success('Notificación enviada')

        } catch (error) {
            toast.error('Error al mandar la notificación')
            Sentry.configureScope(scope => scope.setTransactionName('Error centerTopic in reuse'))
            Sentry.captureException(error);
        }
        try {
            await updateNot(push.docId as string)
        } catch (error) {
            Sentry.configureScope(scope => scope.setTransactionName('Error updatenot'))
            Sentry.captureException(error);
        }
        setloading(false)
    }
    return (
        <>
            {
                loading ?
                    <Spinner />
                    :
                    <ActionButton text={'Reutilizar'} action={reuse} />
            }
        </>
    )
}
