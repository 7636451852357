import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Form } from '@components/formik/Form'
import { InlineInput } from '@components/formik/InlineInput'
import { TextArea } from '@components/formik/TextArea'
import { JustEnd } from '@assets/sass/bootstrapEx'
import { SubmitBtn } from '@components/formik/SubmitBtn'
import { useAppSelector } from '@redux/store/hooks'
import { selectorEditId } from '@redux/features/uiSlice/uiSlice'
import { useAuth } from 'app/modules/auth'
import toast from 'react-hot-toast'
import { callCloud } from '@lib/helpers/callCloud'
import { PushCenter } from '@models/nots/pushCenter'
import { collection, doc, setDoc } from 'firebase/firestore'
import { db } from '@lib/firebase'
import * as Sentry from '@sentry/react';
import { selectCenter } from '@redux/features/gquerySlice/gquerySlice'

const initialValues = { title: '', mess: '' }
const schema = Yup.object().shape({
    title: Yup.string().required('Es necesario un título'),
    mess: Yup.string().required('Es necesario un mensaje')
})

export const CreateNot = () => {
    const { currentUser } = useAuth()
    const center = useAppSelector(selectCenter)
    const [loading, setloading] = useState<boolean>(false)
    const [usersPush, setusersPush] = useState<number>(0)
    const id = useAppSelector(selectorEditId)
    const formik = useFormik({
        initialValues,
        validationSchema: schema,
        onSubmit: async (values, { setValues, resetForm }) => {
            setloading(true)
            try {
                const centerTopic = callCloud('centerTopic');
                const sended = await (await centerTopic({ title: values.title, mess: values.mess, notificationId: center?.notificationId })).data
                if (sended) {
                    toast.success('Notificación enviada')
                } else {
                    toast.error('Error al mandar la notificación')
                }
            } catch (error) {
                toast.error('Error al mandar la notificación')
                setloading(false)
                Sentry.configureScope(scope => scope.setTransactionName('centerTopic'))
                Sentry.captureException(error, { user: { id: center?.centerName } });
                return;
            }
            const date = new Date().toLocaleDateString('es-Es');
            const col = collection(db, 'pushCenter')
            const ref = doc(col);
            try {
                const push: PushCenter = {
                    title: values.title,
                    mess: values.mess,
                    numUsers: usersPush,
                    center: center!.centerName,
                    docId: ref.id,
                    dateShow: date,
                    lastSender: currentUser!.uid
                }
                await setDoc(doc(db, `pushCenter/${push.docId}`), push)
            } catch (error) {
                Sentry.configureScope(scope => scope.setTransactionName('setpushcenter'))
                Sentry.captureException(error, { user: { id: center?.centerName } });
            }
            setloading(false)
            setValues({ ...initialValues })
            resetForm()
        },
    })

    useEffect(() => {
        const num = Number(id);
        setusersPush(num)
    }, [id])

    return (
        <div className='p-4'>
            <Form formikVal={formik} >
                <InlineInput name={'title'} label={'Título de la notificación'} />
                <TextArea name={'mess'} rows={4} label='Escribe el mensaje' />
                <div className={`${JustEnd} mt-5`}>
                    <SubmitBtn loading={loading} />
                </div>
            </Form>
        </div>
    )
}
