import { useBillContext } from "@lib/context/bill/BillCxt";
import { calculByMethod } from "@lib/helpers/bills/calculByMethod";
import { CrossSell } from "@models/sells/crossSell";
import { TypeSells } from "@models/sells/helpersSells";
import { SimpleSell } from "@models/sells/sell";
import { useEffect, useMemo, useState } from "react";

export default function useBill(type: TypeSells | null) {
  const { sells } = useBillContext();
  const [clean, setclean] = useState<SimpleSell[]>([])
  const [outputs, setoutputs] = useState<SimpleSell[]>([])
  const [totsCol, settotsCol] = useState({
    total: 0,
    tar: 0,
    ef: 0,
    others: 0,
    mix: 0,
    online: 0
  })
  useEffect(() => {
    const allOutputs = sells.map((v) => v.sells).reduce((acc, curr) => acc.concat(curr), [])
    setoutputs(allOutputs)
    setclean(allOutputs.filter(el => el.paymentMethod !== 'Ticket'))
  }, [sells])

  useEffect(() => {
    if (type === null) {
      const totalOnline = sells.filter(el => el.paymentIntentId !== undefined && el.paymentIntentId !== '')
      .reduce((a, b) => +a + +b.amount, 0)
      const crossSells = sells.filter(el => el.cross === true) as CrossSell[];
      const tarCross = crossSells.reduce((a, b) => +a + +b.tarQnt, 0);
      const efCross = crossSells.reduce((a, b) => +a + +b.efQnt, 0);
      const totalCross = tarCross + efCross;
      const normalSells = sells.filter(el => el.cross === false).map((v) => v.sells).reduce((acc, curr) => acc.concat(curr), [])
      const { total, ef, tar, others } = calculByMethod(normalSells)
      settotsCol({
        total: total + totalCross,
        others: others,
        ef: ef + efCross,
        tar: tar + tarCross,
        mix: 0,
        online: totalOnline
      })
    } else {
      const crossSellsOutputs = sells.filter(el => el.cross === true).map((v) => v.sells)
      .reduce((acc, curr) => acc.concat(curr), []) as SimpleSell[];
      const mix = crossSellsOutputs.filter(el => el.type === type).reduce((a, b) => +a + +b.amount, 0);
      const normalSells = sells.filter(el => el.cross === false).map((v) => v.sells).reduce((acc, curr) => acc.concat(curr), []).filter(el => el.type === type)
      const { total, ef, tar, others } = calculByMethod(normalSells)
      settotsCol({
        total: total + mix,
        others: others,
        ef: ef,
        tar: tar,
        mix: mix,
        online: 0
      })
    }

  }, [sells, type])



  const prePay: number = useMemo(() => {
    return type === null || type === 'BOOKS' ? clean.filter(el => el.prepay !== undefined).reduce((a, b) => +a + +(b.prepay as number), 0) : 0
  }, [clean, type])


  return {
    totsCol,
    prePay,
    clean,
    outputs
  }

}